import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

 const services = [
        {
            "name":"IT-Recycling",
            "icon":PrecisionManufacturingIcon,   
            "iconColor":"#00804b",
            "title":"IT Recycling & Disposal",
            "descripton":"We recycle all computers, IT hardware, mobile phones and many other electronics in a responsible way.",
            "subDescription":`<h3>Give your equipment a second life with
            hardware recycling</h3>
            We manage all aspects of hardware recycling. We start with the collection of unused IT equipment, disassemble it, securely destroy any data, and carry out rigorous quality testing on any reusable component before repurposing.

            We remove the stress and strain from hardware recycling with our simple and secure service, making it easy and cost-effective for your business to do the right thing with unused equipment.

            Our service is not only complete, but fully trackable at every stage.
            `
        },
        {   "name":"Purchase",
            "icon":ShoppingCartIcon,   
            "iconColor":"rgb(131 131 131)",
            "title":"We Are Buying", 
            "descripton":"We are a wholesaler specialized in the resale of IT lease returns trade of used IT equipment.",
            "subDescription":`<h3>YOU ARE A LEASING COMPANY ...</h3>
            <h4>You are looking for a professional company that can buy your leasing returns?</h4>
           <p> We are a wholesaler specialized in the resale of IT lease returns trade of used IT equipment. Our international network of customers drives us to constantly looking for new suppliers.
            We have the capacity to audit large quantities of IT equipment and assure secure data deletion.
            </p>    
            <br/>
            <h3>YOU ARE TRADING USED IT EQUIPMENT ...</h3>
            <h4>You are a wholesaler, a shopkeeper, a refurbisher or a systemhouse?</h4>
          <p>  We can make you an offer for your used IT hardware. Send us your stocklist or write us!
<p>
            <br/>
            <h3>CAPITALIZE FROM YOUR OLD IT-DEVICES ...</h3>
            <br/>
            At some point every company reaches the moment when it becomes necessary to change its IT-infrastructure. This raises the question:
            <br/>
            What to do with my old machines?
            <br/>
            Whether you are a company, an administration or a educational institution, we buy your end-of-lease and purchased hardware at a fair price.

            You worry about the data in your old data carriers? We take data security seriously. We take care of the correct and safe handling of your data. Hardware that cannot be used for resale will be properly disposed or recycled.
                `
        },
        {   "name":"We-Offer",
            "icon":LocalShippingIcon,   
            "iconColor":"rgb(0 0 0)",
            "title":"We Are Selling", 
            "descripton":"We offer you high-quality, professionally prepared business IT devices.",
            "subDescription":`<h3>CHOOSING USED IT BUSINESS EQUIPMENT MEANS BUYING HIGH QUALITY AT A REASONABLE PRICE</h3>
                                <br/>
                                We offer you high-quality, professionally prepared business IT devices. Our inventory consists mainly of equipment from reputable brand manufacturers (including HP, DELL, LENOVO), which are more durable and have much better hardware compared to standard end user products. Even used, they still meet most of today's performance requirements.

                                We work mainly with leasing companies as well as with medium and large-sized companies that sell us their leasing returns. Our products are between one to five years old, which means that most of them still have manufacturer warranty and have a valid Windows operating system licence.

                                <br/>
                                "After remarketing process, our used IT meets today's performance requirements."
                                <br/>
                                We operate operate exclusively in the B2B business. We do not sell to end customers. Having that said, our clientele mainly includes wholesalers, resellers, shop operators for end customers and service partners of companies.
                                <br/>
                                <h3>Our IT remarketing work steps </h3>
                                <br/>
                                For maximum customer satisfaction, we want to be as transparent as possible by explaining all our work steps in a clear manner:
                                <br/>
                                The products are picked up from the supplier.
                                <br/>
                                At the arrival at the warehouse, a control of the goods are carried out.
                                <br/>
                                All products receive an identification number.
                                <br/>
                                All equipment is professionally cleaned and old labels / identifications removed.
                                <br/>
                                Data is professionally deleted according to internationally recognized standards (with Toolstar or Blancco).
                                <br/>
                                    An optical and technical test is conducted for all machines.
                                    <br/>
                                    A documentation with a grade (A, A-, B, and C) is created for each device.
                                    <br/>
                                    Devices are processed and packaged for resale.

            `
        }
    ]

    export default services