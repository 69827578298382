
const productData = [
    {   img:require("../assets/laptops.jpeg"),
        name:"Laptops",
        Descrip:"Our business laptops from renowned manufacturers. "
        ,"link":"laptop",
        "DetailsTitle":"MOBILE COMPANIONS FROM RENOWNED MANUFACTURERS",
        "Details":`<p>Notebooks / laptops are necessary tools for todays' working life. Mobility has become a preresquisite in our increasingly digitalized world. We offer a variety of business laptops from renowned manufacturers. This allows to react and adjust rapidly to customer demands.</p>
                    <p>All devices are carefully tested and rated (audited). Our customers receive a detailed description of the specification and the defects for each device.

                    Data on all machines are professionally deleted.</p>
        `
    },

    {
        img:require("../assets/perosncom.jpeg"),
        name:"Personal computers & workstations",
        Descrip:"Reliability at low price for your business with our business personal computers."
        ,"link":"Personal-computers-workstations",
        "DetailsTitle":"OUR SUPPORT TO YOUR DIGITALIZATION",
        "Details":`<p>Digitalization is part of todays' work life. Our personal computers and workstations offer the necessary performance to withstand the continuous software-updates that requires more and more processing power.

        Our business personal computers are cheap and offer stability and reliability for office work.
        
        Our business workstations are real power machines and offer the necessary processing performance for high demand applications.</p>
        <p>All devices are carefully tested and rated (audited). Our customers receive a detailed description of the specification and the defects for each device.

        Data on all machines are professionally deleted.</p>`
    },
    {   
        img:require("../assets/screengra.jpeg"),
        name:"Screens",
        Descrip:"High resolution at a low price"
        ,"link":"screens",
        "DetailsTitle":"BEST PRICE-PERFORMANCE RATIO FOR YOU!",
        "Details":`<p>24 or 27“, Display-Port or HDMI: High-end technology at low price. Compare to new goods, our second-hand screens are just as good. Here you can benefit from our bargains.</p>
        <p></p>`
    },
    {
        img:require("../assets/tabletgraphic.jpeg"),
        name:"Tablets & smartphones",
        Descrip:"Stay mobile with our tablets and smartphones at low price."
        ,"link":"tablets-smartphones",
        "DetailsTitle":"THE CHEAP WAY TO STAY MOBILE",
        "Details":`<p>You are looking for mobile devices at an affordable price? Choose from our stock of used but fully functional smartphones and tablets. Apple, Samsung, Nokia, Lenovo, and our selection increases.

        All devices are carefully tested and rated (audited). Our customers receive a detailed description of the specification and the defects for each device.
        
        Data on all devices are professionally deleted.
        
        All our smartphones and iPhones are unlocked.</p>`
    },
    {
          img:require("../assets/accesss.jpeg"),
        name:"Parts & accessories",
        Descrip:"Find the right components, spare parts & hardware for you needs",
        "link":"Parts-accessories",
        "DetailsTitle":"ACCESSORIES, COMPONENTS & HARDWARE FOR SPECIAL NEEDS",
        "Details":`<h3>Accessories</h3>
        <p>Mice, keyboards, Power supplies, RAMs, Hard drives, and much more... The market offers a large variety of products and it can be difficult to stay on top of things. We can help you find what you need with our extensive accessories inventory.</p>
    `
    },


]
export default productData