import React from 'react'
import "./SolutionDetails.css"

export default function SolutionDetails() {
  return (
    <div className='solutionsContainer'>
        <h2 className='solutionsTitle'>IT HARDWARE AND SERVICES FROM ONE PROVIDER</h2>
        <br/>
        <p>We offer services for small and large business customers. Our wide range of solutions for you are customized to your needs. Learn more about our solutions and contact us!</p>
        <h4>IT REMARKETING</h4>
        <p>We trade in used and refurbished IT hardware through our intensive international distribution network.</p>
        <h3>You are looking for IT-hardware</h3>
        <p>As a wholesale company, we have a rich supply of used IT for you!
        We offer a large selection of used IT products for a reasonable price. We guarantee a fair price calculation through our auditing process.
        We promote fair pricing through our in-depth audit process. All devices are carefully checked and rated. We provide to our customers a detailed description of the technical details and a description of the existing deficiencies and usage restrictions.</p>
        <br/>
            <h4>You offer IT-hardware</h4>
            <p>You are looking to sell your old hardware and make some money!
            We are happy to assist you with in the sale of your IT hardware. Benefit from our own auditing process, our international sales network and services from your IT Remarketing Specialist. 
                                 </p>        
            <br/>
          
            <h4>IT-Lifecycle-Management</h4>
            <br/>
            <p>We can help you with the recycling of your old hardware. Our formost goal is to prolonge the working life of your IT products with the means of remarkeing or refurbishing. Hardware that can't be reused will be recycled.</p>
            <h4>Our professionnal IT recycling services:</h4>
            <p>IT recycling</p>
    </div>
  )
}
