import React from 'react'
import "./Whywe.css"

export default function Whywe() {
  return (
    <div className='whyweContainer'>
      <h2 className='whyweTitle'>Why EgyTechnology Management?</h2>
      <p>When considering Computer Recycling, the first question is what are the things I need to consider when choosing a partner, at Egy-Technology, we feel that this is very important to choose well.
    <br/>
    </p>
      <br/>
      <h2>WHY SHOULD YOU CHOOSE Egy-Technology MANAGEMENT:</h2>
      <br/>
      <h3>ON SITE SERVICES</h3>
      <p>Whilst focus on the actual process of data destruction or sanitisation is key there are a whole host of areas which require attention to ensure a safe, controlled environment for these services.

      This section covers documented evidence to show the physical control over the environment to ensure that IT assets under the control of Egy-Technology Management are securely managed; Egy-Technology Management’s facility is secure; access to the facility is secure; staff (both Egy-Technology Management and client) is clear on their respective roles and responsibilities within the process.

      In addition, there is a crucial need for a formal and controlled process to be followed to ensure inventory control is in place and the chain of custody on the assets is clear.

      Finally, the use of the correct tools to perform data destruction or sanitisation is essential as well as the same verification and quality checks being in place before a final sign off and release stage.</p>

      <p>Full inventory of equipment to be verified before work to commence.</p>
      <p>Written documented evidence of the following:</p>
      <p>Process for data erasure / destruction.</p>
      <p>Agreed schedule of works to include any additional services on-top of data erasure.</p>
      <p>All personnel undergo screening to a minimum level of BS7858.</p>
      <p>Any further off-site processing to be agreed prior to work commencing.</p>
      <p>All data bearing devices will be scanned, HDDs removed, scanned and  then physically destroyed. Should an HDD be missing from a parent machine, a member of the IT Team must be informed immediately.</p>
      <p>References available for on-site works.</p>
      <p>Insurance policies are in place in accordance with the clients required level.</p>
      <p>Risk assessments and site survey carried out before the commencement of work.</p>
      <br/>
      <br/>
      <h3>BUSINESS CREDENTIALS</h3>
      <p>When choosing a supplier for these critical IT Security Services the starting point should be an assessment of the status of their business. This includes basic financial checks, verification of claims for Certifications, levels of insurance and confirmation and verifying of published references.</p>
      <p>Satisfactory IG Toolkit</p>
      <p>A Dunn and Bradstreet risk indicator of 2 or better</p>
      <p>Egy-Technology Management to provide a full written procedure for each stage of equipment processing which outlines data destruction, auditing, test, and repair processes.</p>
      <p>ISO 9001.</p>
      <p>ISO 14001.</p>
      <p> ISO 27001</p>
      <p>Data security protection toolkit</p>
      <p>Member of the British Security Industry Association</p>
      <p>Public Liability Insurance</p>
      <p>Employee Liability</p>
      <p>Professional Indemnity Insurance</p>
      <p>ICO Registration</p>
      <p>A written contract with each client covering all services undertaken.</p>
      <br/>
      <h3>PRE-COLLECTION CRITERIA</h3>
      <p>A robust process must begin with an exchange of information and documentation between us and the Client that clearly identifies the number of assets for collection; how the assets are identified (e.g. serial number); an agreed asset reconciliation process; sensitivity of data-bearing assets; confirmation of service required; pre-collection agreements and service level agreements and transfer of custody (if any) arrangements. Without this information, the chain of custody and service structure cannot be initiated until equipment arrives on site. Without these foundations, the process as a whole is weak and potentially insecure.</p>
      <p>Written agreement for the service to be undertaken prior to work commencing.</p>
      <p>Inventory List Required –box count minimum standard.</p>
      <p>Confirmation and agreement on the type and mode of logistics.</p>
      <p>Peripherals and accessories to be included are encouraged for re-use.</p>
      <p>Inventory list required which provides unique identifying reference</p>
      <p>Inventory list required and includes hard drive serial numbers.</p>
      <p>Transfer of custody to be agreed in writing.</p>
      <br />
      <h3>LOGISTICS</h3>
      <p>Risk of loss of assets during the transportation process is assessed both in terms of the probability of physical loss or theft but also in control and management of the chain of custody. It is imperative that equipment collected is controlled such that verification on receipt is confirmed and hence the risk of potential losses during logistical transfer is mitigated.</p>
      <p>GPS Tracked, dedicated vehicles to be used.</p>
      <p>Electro or mechanical immobiliser.</p>
      <p>Alarmed.</p>
      <p>Communication with base via telephone or radio.</p>
      <p>Solid sided vehicles. Curtain sides are NOT allowed.</p>
      <p>Imposter prevention is essential – Photo ID for driver provided.</p>
      <p>Drivers to security cleared to at least BS7858.</p>
      <p>Inventory managed on and off the vehicle with a box count.</p>
      <p>Additional or non-inventoried items should be manually added to paperwork and signed for</p>
      <p>Equipment is packaged properly at point of collection. The use of bubble wrap, crates and cages used. This is to ensure the quality of the equipment is maintained during transportation and therefore re-use opportunity is maximized.</p>
      <p>Vehicles with generic sign writing is used.</p>
      <p>Imposter prevention – The issuing of drivers details and vehicle details issued to client prior to collection.</p>
      <p>To have the ability to provide uniformed staff and un-uniformed staff to accommodate client demands.
 </p>
 <br/>
    <h3>EXTERNAL SITE SECURITY</h3>
    <p>All aspects of Egy Technology Management’s site needs to be secured to deter either the casual opportunist intruder or a determined planned attack. As such both physical and technology deterrents need to provide overall site security.</p>
    <p>Egy Technology-Management have an intruder alarm installed to EN50131-1, monitored by an approved BS5979 alarm</p>
    <p>Vehicles are unloaded under cover of CCTV.</p>
    <p>Vehicles are unloaded inside the facility.</p>
    <p>To deter the casual opportunist Egy-Technology Management do not have any external signs of IT activities such as obvious signage, equipment stockpiles or advertisements.</p>
    <p>Egy-Technology Management have site gates and external fencing. Ram Bars, grills and other physical measures are also used.
 </p>
    <br/>
    <h3>INTERNAL SECURITY</h3>
    <p>Controlling the potential for insider theft is a well recognised element in the management of internal security. Egy-Technology Management implements robust internal security countermeasures, complete staff spot checks and have controls that mitigate the risk of this potential threat.  Egy-Technology operates the Administrative Office separate from the Operational Activities.</p>
    <p>All personnel that work in or have access to the data processing area undergo screening to a minimum level of BS7858.</p>
    <p>No visitors are allowed into data processing areas unless they have their identification verified and it is recorded. (NB: This includes drivers, tradesmen and office visitors)</p>
    <p>All internal access points to the processing facility have controlled entry systems.</p>
    <p>CCTV is essential for the following areas:</p>
    <p>Unloading and loading areas and are extensively covered.</p>
    <p>Access points.</p>
    <p>Data processing area.</p>

    <p>CCTV covered is backed up daily and stored in a fire proof safe and retained for a minimum of 31 days.</p>
    <p>The premises are not shared with any other organisation.</p>
    <p>Visitors wear clearly visible badges / vests and are escorted in processing areas at all times.</p>
    <p>The processing area is physically segregated from any other activity being carried out at the premises.</p>
    <p>The processing facility has its own security checks from the general facility to include bag and coat check in, staff searches and controlled access.</p>
    <p> Egy-Technology use an EN:50131-1 which is monitored.</p>
    <p>All personnel are required to sign a Non-Disclosure Agreement as part of their employment.</p>

    <br/>
    <h3>PROCESS</h3>
    <p>The chain of custody is imperative in risk management within asset retirement and as such once inside the confines of the facility the continuation of a controlled process is critical. Each stage is reviewed and risk of failure in the process is assessed. Egy-Technology Management look for the potential scenarios whereby a robust process might fail due to an unforeseen issue and assesses whether that is an unacceptable risk. Review of both written and actual processes is undertaken as well as assessment of the technology used to perform and manage services.</p>
    <p>Every collection is processed and individually tracked within 24 hours of receipt at the facility.</p>
    <p>Data carrying equipment is stored in a segregated area from post process equipment. This is in a secure and physically segregated area.</p>
    <p>Each asset is audited to obtain full build specification.</p>
    <p>Each asset is tested to check functionality.</p>
    <p>Each asset is graded to confirm physical condition.</p>
    <p>Data carrying items undergo data sanitation process using National Approved CESG Approved Software.</p>
    <p>Any data carrying device which fails is removed from parent machine, individually barcoded and on-site physical. destruction will take place within a controlled and documented process.</p>
    <p>Every data carrying device which is received for end of life processing will undergo the same process regardless of any assurances from the Client that they have already destroyed the data.</p>
    <p>There is a documented quality control process which will test a sample number of hard drives and all other data carrying assets after the data erasure process to ensure that the data has been overwritten.</p>
    <p>All equipment undergoes de-branding where asset tags and other non-relevant markings are removed.</p>
    <p>All equipment which is to be re-used is cosmetically cleaned and where financially viable any missing components will be replaced to ensure maximum opportunity of re-use.</p>
    <p>Evidence of how the inventory is managed throughout the process is shown.</p>
    <p>Data carrying and data safe items are physically segregated.</p>
    <p>The maximum length of time from the point of collection until the point of data destruction is 10 working days.</p>
    <p>The chain of custody should start before collection and shall be verified on receipt at the facility, and at the end of the process itself.</p>
    <p>Each device has the chassis opened to check for unconnected data carrying media such as hard disk drive and full physical checks for other storage devices made. (CD drawers etc)</p>
    <p>Loose or separate data carrying media will be individually tracked on the system and classified as a separate asset. The same processes and procedures are applied to these.</p>
    <p>The destruction of Confidential Materials takes place within one working day from arrival at our facility.</p>
    <br/>
    <h3>SOFTWARE SYSTEMS</h3>
    <p>Records created during the processing of assets need to be safeguarded such that in the event of a disaster or theft that the records can be recovered. Egy-Technology Management can provide details of backup policies are to include the frequency of backup, media-type used, where it is stored, and finally how regularly recovery is re-tested.</p>
    <p>Processing Database is backed up daily.</p>
    <p>Backup is stored in a secure, fire retardant location on site.</p>
    <p>Back Ups tested monthly.</p>
    <br/>
    <h3>ENVIRONMENTAL</h3>
    <p>Environmental legislation is a difficult, oft misunderstood topic that is subject to wide interpretations. There is a variance in interpretation and as a result, an opportunity arises to operate under exemptions whilst processing EEE, UEEE or WEEE.</p>
    <p>However Egy-Technology Management insist on being Environment Agency approved ATF and AATF and can provide environmental permits and credentials, written procedures for all recycling activity undertaken on site and management of downstream partners who may perform subsequent processes on e-waste.</p>
    <p>Egy-Tehnology Management also encourage maximum opportunity of re-use of all equipment with the Client being identified as the key decision maker within the process.</p>
    <p>A written process for treatment of waste (WEEE) is provided.</p>
    <p>All material received is classed by Egy-Technology Management as WEEE, therefore we will hold an environmental permit (waste management licence) ie. ATF & AATF</p>
    <p>We hold a Duty of Care Licence</p>
    <p>We hold a Waste Carrier and Broker Permit.</p>
    <p>When material received is hazardous waste it must be managed in accordance with:</p>
    <p>The Hazardous Waste (England and Wales) Regulations 2005 (as amended) where it arises in England, Wales</p>
    <p>The Hazardous Waste Regulations (Northern Ireland) 2005 where it arises in Northern Ireland</p>
    <br/>
    <h4>The Special Waste Regulations 1996 (as amended) where it arises in Scotland. (See Section 5)</h4>
    <p>When we receive WEEE, we are able to demonstrate that we treat WEEE according to the guidance on best available treatment, recovery and recycling techniques (BATRRT – See Section 5).</p>
    <p>Each collection has assets identified as WEEE (waste).</p>
    <p>Waste transfer notes or hazardous waste consignment notes are created for movement of all WEEE (waste) to and from our facilities.</p>
    <p>Egy-Technology Management have personnel in place with suitable levels of qualification (Certificate of Technical Competence COTC) issued by The Waste Management Industry Training and Advisory Board (WAMITAB)</p>
    <p>Evidence of Environment Agency inspections are available.</p>
    <br/>
    <h3>RE-USE</h3>
    <p>Not only does re-use maximise revenue opportunity but it is also significantly better for the environment. Legislation is leaning towards encouraging re-use rather than re-cycle but as the first focus of Egy-Technology Management is data security it is a trade-off between ensuring data is securely sanitised and making processing of the asset financial viable for re-use. Egy-Technology Management encourages best commercial practise in functionality testing, repair and re-engineers but also consider commercial viability of these practices.  Egy-Technology Management provide a return for end of life equipment.</p>
    <p>During the auditing process a decision tree is in place which allows an incomplete or non-functioning asset to undergo some remedial process / repair to make it good for re-use. This decision tree has a written process which shows time verses financial viability of repair.</p>
    <p>Records of all assets either sold on or passed for further processing are kept. These records include unique tracking references of all equipment passed onto downstream suppliers.</p>
    <p>Items which can be damaged by ESD are handled in ESD safe working environments.</p>
    <p>Proactive assessments, audits and management of downstream brokerage and recycling partners are completed annually.</p>
    <br/>
    <h3>REPORTING</h3>
    <p>Reporting promotes control over the process and therefore self publicises excellence, but it also demonstrates transparency over the various activities taking place. This openness is essential to show the number of quality stages in a truly robust IT Asset Disposal process.</p>
    <p>Egy-Technology Management provide an Asset Register, Volume Reports, Waste Transfer Notes, Duty of Care Notes, Hazardous Waste Consignment Notes and Certificates of Destruction.</p>
    <p>Detailed audits are supplied and include asset level detail of build, data destruction and downstream destination of the asset.</p>
    <p>Environmental reporting identifies weight of equipment collected, processed and recycled.</p>
    <br/>
    <br/>
    </div>
  )
}
