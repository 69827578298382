import React from 'react'
import "./DataSecurityDetails.css"

export default function DataSecurityDetails() {
  return (
    <div className='dataSecurityDetailsContainer'>
        <h2 className='dataSecurityDetailsTitle'>
        YOUR DATA SECURITY IS OUR PRIORITY
        </h2>
    <br/>
        <p>Sensitive data have to be protected from third parties. With our data security processes, we guarantee that your data is deleted quickly and securely and your IT equipment is anonymized.

      Our trained staff and our secure workspaces allow a professional and safe handling of all IT equipment, from transport to remarketing.</p>
      <p>The data security of your company and that of your customers is very important to us.
      </p>
    </div>
  )
}
